
<template>
  <!-- This component preview PDF lesson for the owner -->
  <div>
    <a href="#" v-b-modal="lesson.uuid" v-if="lesson.file !== ''">Preview</a>

    <b-modal title="Preview video" :id="lesson.uuid" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <h5>{{ lesson.name }}</h5>
      <pdf v-for="i in numPages" :key="i" :src="src" :page="i" style="display: inline-block; width: 100%"></pdf>
    </b-modal>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: {
    lesson: {},
  },

  components: {
    pdf,
  },

  data() {
    return {
      numPages: undefined,
    };
  },

  computed: {
    src() {
      if (this.lesson.file === "") {
        return pdf.createLoadingTask(`${window.location.origin}/Samplet-Presention.pdf`);
      }
      return pdf.createLoadingTask(this.lesson.file);
    },
  },

  beforeMount() {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
};
</script>
