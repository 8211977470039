<template>
  <!-- Createn new video lesson -->
  <div>
    <a href="#" class="btn btn-secondary btn-sm" v-b-modal="modalID">
      <i class="icon icon-film"></i>
      Add Video Lesson
    </a>

    <b-modal title="Create video" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <form @submit.prevent="handleCreate" class="mt-4">
        <div class="form-group">
          <input type="text" class="form-control" v-model="lesson.name" placeholder="Video Title" :class="{ 'is-invalid': $v.lesson.name.$error, 'is-valid': !$v.lesson.name.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.lesson.name.required">Video title is required.</span>
            <span v-if="!$v.lesson.name.minLength">Video title must be at least {{ $v.lesson.name.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <!-- <div class="form-group">
          <input type="number" class="form-control" v-model="lesson.duration" placeholder="Video Duration" :class="{ 'is-invalid': $v.lesson.duration.$error, 'is-valid': !$v.lesson.duration.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.lesson.duration.required">Video duration is required.</span>
            <span v-if="!$v.lesson.duration.numeric">Video duration must be numeric.</span>
          </div>
        </div> -->
        <div class="form-group">
          <vue-editor v-model="lesson.description" :editorToolbar="editorToolbar"></vue-editor>
          <!--  <textarea class="form-control" v-model="lesson.description" cols="30" rows="2" placeholder="Video Description" :class="{ 'is-invalid': $v.lesson.description.$error, 'is-valid': !$v.lesson.description.$invalid }"></textarea> -->
          <div class="text-danger" v-show="$v.lesson.description.$error">
            <span v-if="!$v.lesson.description.required">Video description is required.</span>
            <span v-if="!$v.lesson.description.minLength">Video description must be at least {{ $v.lesson.description.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <div class="form-group">
          <!-- <input v-model="lesson.file" type="file" class="form-control" placeholder="Video File" /> -->
        </div>
        <button type="submit" class="btn btn-secondary" :disabled="isDisabled">{{ createButton }}</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";
const btnLabel = "Submit";

export default {
  props: {
    topicUUID: {},
    courseUUID: {},
    topicIndex: Number,
  },

  data() {
    return {
      modalID: "create-video-" + this.topicUUID,
      lesson: {
        name: "",
        duration: "",
        description: "",
      },
      createButton: btnLabel,
      isDisabled: false,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },

  methods: {
    handleCreate(e) {
      this.isDisabled = true;
      this.createButton = "Working...please wait!";

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.createButton = btnLabel;
        this.isDisabled = false;
        return;
      }

      axios
        .post("/authoring/lessons/create/video/" + this.courseUUID + "/" + this.topicUUID, this.lesson)
        .then((res) => {
          if (res.data.status == "success") {
            //console.log(res.data);
            // if post success
            this.$bvModal.hide(this.modalID); // Hide modal after success
            this.$v.$reset();
            this.lesson = {};
            this.createButton = btnLabel;
            this.$swal("Done!", res.data.message, "success");
            // emit back an object contain the topic index and the newly created lesson from server response
            this.$emit("lessonCreated", { topicIndex: this.topicIndex, newLesson: res.data.lesson });
            this.isDisabled = false;
          } else {
            this.createButton = btnLabel;
            this.$swal("Nope!", res.data.message, "error");
            this.isDisabled = false;
          }
        })
        .catch((error) => {
          // handle error
        });
    },
  },

  validations: {
    lesson: {
      name: { required, minLength: minLength(3) },
      //duration: { required, numeric },
      description: { required, minLength: minLength(5) },
    },
  },

  components: {
    VueEditor,
  },
};
</script>

<style>
</style>