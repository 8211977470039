<template>
  <!-- This component preview videos lesson for the owner -->
  <div>
    <a href="#" v-b-modal="modalID" v-if="lesson.file !== ''">Preview</a>

    <b-modal title="Preview video" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <h5>{{ lesson.name }}</h5>
      <vue-plyr ref="plyr">
        <video :autoplay="false" :poster="lesson.course.image" :src="lesson.file" controls allowfullscreen allowtransparency preload="auto">
          <source :src="lesson.file" type="video/mp4" />
        </video>
      </vue-plyr>

      <h5 class="mt-5">Description</h5>
      <div v-html="lesson.description"></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    lesson: {},
  },

  data() {
    return {
      modalID: "videos-preview-" + this.lesson.uuid,
    };
  },
};
</script>
