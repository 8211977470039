<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-sm-8">
        <h3 class="m-0 text-dark">
          {{ course.name }}
        </h3>
        <h6>LESSONS:</h6>
      </div>
      <div class="col-sm-4">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/courses">Courses</router-link>
          </li>
          <li class="breadcrumb-item active">Lessons</li>
        </ol>
      </div>
    </div>

    <!-- Lessons modules -->

    <div class="row">
      <div class="col-12">
        <!-- Create button -->
        <TopicCreate :courseUUID="course.uuid" @topicCreated="topicCreated" modalID="topTopicCreateBtn" />
        <!-- end Create button-->
        <div class="accordion" role="tablist">
          <b-card v-for="(topic, index) in topics" :key="topic.uuid" no-body class="mb-4">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <!-- TOPIC -->
              <b-button block v-b-toggle="'accordion-' + index" variant="dark">
                <table style="width: 100%">
                  <tr>
                    <td width="80" style="text-align: left">
                      <small>MODULE {{ index + 1 }}:</small>
                    </td>
                    <td style="text-align: left">
                      <b>{{ topic.name }}</b>
                    </td>
                    <td width="80" style="text-align: right">
                      <TopicEdit :topic="topic" />
                    </td>
                    <td width="80" style="text-align: right">
                      <TopicDelete :topic="topic" :index="index" @topicDeleted="topicDeleted(index)" />
                    </td>
                  </tr>
                </table>
              </b-button>
              <!-- END  TOPIC -->
            </b-card-header>
            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <!-- children -->
                <div v-for="(lesson, lessonIndex) in topic.children" :key="lesson.uuid">
                  <table style="width: 100%; margin-bottom: 0" class="table">
                    <tr>
                      <td width="100">
                        <small>LESSON {{ lessonIndex + 1 }}:</small>
                      </td>
                      <td width="10">
                        <i class="icon icon-film mr-3" v-if="lesson.type == 'video'"></i>
                        <i class="icon icon-file-pdf mr-3" v-if="lesson.type == 'pdf'"></i>
                        <i class="icon icon-question-circle mr-3" v-if="lesson.type == 'quiz'"></i>
                      </td>
                      <td>
                        <div>{{ lesson.name }}</div>
                      </td>

                      <td width="30" title="Preview">
                        <VideoPreview v-if="lesson.type == 'video'" :lesson="lesson" />
                        <PDFPreview v-if="lesson.type == 'pdf'" :lesson="lesson" />
                      </td>

                      <td width="30" title="Upload file">
                        <FileUpload v-if="lesson.type == 'video'" :lesson="lesson" :topicIndex="index" :lessonIndex="lessonIndex" @lessonFileUploaded="lessonFileUploaded" />
                        <FileUpload v-if="lesson.type == 'pdf'" :lesson="lesson" :topicIndex="index" :lessonIndex="lessonIndex" @lessonFileUploaded="lessonFileUploaded" />
                      </td>

                      <td width="30" title="Edit">
                        <VideoEdit v-if="lesson.type == 'video'" :lesson="lesson" :topicIndex="index" :lessonIndex="lessonIndex" />
                        <PDFEdit v-if="lesson.type == 'pdf'" :lesson="lesson" :topicIndex="index" :lessonIndex="lessonIndex" />
                      </td>

                      <td width="30" title="Delete">
                        <LessonDelete :lesson="lesson" :topicIndex="index" :lessonIndex="lessonIndex" @lessonDeleted="lessonDeleted" />
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- end children -->

                <!-- Create lesson buttons -->
                <div class="mt-3 mb-3" style="width: 100%">
                  <table>
                    <tr>
                      <td>
                        <VideoCreate :topicUUID="topic.uuid" :courseUUID="course.uuid" :topicIndex="index" @lessonCreated="lessonCreated" />
                      </td>
                      <td>
                        <PDFCreate :topicUUID="topic.uuid" :courseUUID="course.uuid" :topicIndex="index" @lessonCreated="lessonCreated" />
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- end Create lesson buttons -->
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <!-- Create Topic button -->
        <TopicCreate :courseUUID="course.uuid" @topicCreated="topicCreated" modalID="bottomTopicCreateBtn" />
        <!-- end Create Topic button-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// Topic/Module
import TopicCreate from "./topic/TopicCreate.vue";
import TopicDelete from "./topic/TopicDelete.vue";
import TopicEdit from "./topic/TopicEdit.vue";
// Video lesson
import VideoPreview from "./video/VideoPreview.vue";
import VideoCreate from "./video/VideoCreate.vue";
import VideoEdit from "./video/VideoEdit.vue";
// PDF lesson
import PDFPreview from "./pdf/PDFPreview.vue";
import PDFCreate from "./pdf/PDFCreate.vue";
import PDFEdit from "./pdf/PDFEdit.vue";

// Shared components
import LessonDelete from "./shared/LessonDelete.vue";
import FileUpload from "./shared/FileUpload.vue";

export default {
  components: {
    // Topic/Module
    TopicCreate,
    TopicDelete,
    TopicEdit,
    // Video lesson
    VideoPreview,
    VideoCreate,
    VideoEdit,
    // PDF lesson
    PDFPreview,
    PDFCreate,
    PDFEdit,
    // Shared components
    LessonDelete,
    FileUpload,
  },

  data() {
    return {
      course: { uuid: "" },
      topics: [],
    };
  },

  methods: {
    // When  topic/module created, append the newly created topic/module to the bunch
    topicCreated(newTopic) {
      this.topics.push(newTopic);
    },

    // When topic/module deleted, remove from the topic/module from the bunch
    topicDeleted(index) {
      this.topics.splice(index, 1);
    },

    // When new lesson created, append the newly created lesson to the bunch, i.e. the topic's children
    lessonCreated(obj) {
      this.topics[obj.topicIndex].children.push(obj.newLesson);
    },

    // When new lesson deleted, remove the deleted lesson from the bunch.
    lessonDeleted(obj) {
      this.topics[obj.topicIndex].children.splice(obj.lessonIndex, 1);
    },

    // when upload completed successfully
    lessonFileUploaded(obj) {
      this.topics[obj.topicIndex].children[obj.lessonIndex].file = obj.newFileLink;
    },
  },

  beforeMount() {
    axios
      .get("/authoring/lessons/" + this.$route.params.courseUUID)
      .then((res) => {
        this.course = res.data.course;
        this.topics = res.data.topics;
      })
      .catch((error) => {});
  },
};
</script>

<style>
</style>