<template>
  <div>
    <a href="#" v-b-modal="modalID">Edit</a>

    <b-modal title="Edit Lesson (Video)" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <form @submit.prevent="handleUpdate" class="mt-4">
        <!-- input type="hidden" v-model="lesson.courseUUID" value="$Course.Uuid" />
      <input type="hidden" v-model="lesson.topicUUID" value="$topic.ID" />-->
        <div class="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control" v-model="lesson.name" placeholder="Video Title" :class="{ 'is-invalid': $v.lesson.name.$error, 'is-valid': !$v.lesson.name.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.lesson.name.required">Video title is required.</span>
            <span v-if="!$v.lesson.name.minLength">Video title must be at least {{ $v.lesson.name.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <!-- <div class="form-group">
          <label for="">Duration</label>
          <input type="number" class="form-control" v-model="lesson.duration" placeholder="Video Duration" :class="{ 'is-invalid': $v.lesson.duration.$error, 'is-valid': !$v.lesson.duration.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.lesson.duration.required">Video duration is required.</span>
            <span v-if="!$v.lesson.duration.numeric">Video duration must be numeric.</span>
          </div>
        </div> -->
        <div class="form-group">
          <label for="">Description</label>
          <vue-editor v-model="lesson.description" :editorToolbar="editorToolbar"></vue-editor>
          <!-- <textarea class="form-control" v-model="lesson.description" cols="30" rows="2" placeholder="Video Description" :class="{ 'is-invalid': $v.lesson.description.$error, 'is-valid': !$v.lesson.description.$invalid }"></textarea> -->
          <div class="text-danger" v-show="$v.lesson.description.$error">
            <span v-if="!$v.lesson.description.required">Video description is required.</span>
            <span v-if="!$v.lesson.description.minLength">Video description must be at least {{ $v.lesson.description.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <div class="form-group">
          <!-- <input v-model="lesson.file" type="file" class="form-control" placeholder="Video File" /> -->
        </div>
        <button type="submit" class="btn btn-secondary" :disabled="isDisabled">
          {{ editButton }}
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

const myBtn = "Update Video Lesson";

export default {
  props: {
    lesson: {},
    topicIndex: Number,
    lessonIndex: Number,
  },

  data() {
    return {
      editButton: myBtn,
      isDisabled: false,
      modalID: "videos-edit-" + this.lesson.uuid,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },

  methods: {
    handleUpdate(e) {
      this.isDisabled = true;
      this.editButton = "Working ... please wait!";

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isDisabled = false;
        return;
      }

      axios
        .post("/authoring/lessons/edit/" + this.lesson.uuid, this.lesson)
        .then((res) => {
          this.isDisabled = false;
          if (res.data.status === "success") {
            //this.$store.state.topics[this.topicIndex].children[this.lessonIndex].name = res.data.lesson.name;
            //this.$store.state.topics[this.topicIndex].children[this.lessonIndex].duration = res.data.lesson.duration;
            //this.$store.state.topics[this.topicIndex].children[this.lessonIndex].description = res.data.lesson.description;
            this.$v.$reset();
            this.editButton = myBtn;
            this.$bvModal.hide(this.modalID);
            this.$swal("Done!", res.data.message, "success");
     
          } else {
            this.$swal("Nope!", res.data.message, "error");
            this.editButton = myBtn;
          }
        })
        .catch((error) => {
          // handle error
        });
    },
  },

  validations: {
    lesson: {
      //duration: { required, numeric },
      name: { required, minLength: minLength(3) },
      description: { required, minLength: minLength(5) },
    },
  },

  components: {
    VueEditor,
  },
};
</script>

<style>
</style>