<template>
  <!-- This component will create in topic/module -->
  <div>
    <button class="btn btn-success mb-5" v-b-modal="modalID">
      <i class="icon icon-plus"></i>
      Create new module
    </button>

    <b-modal title="Create New Module" :id="modalID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <form @submit.prevent="topicCreate">
        <div class="form-group mb-2">
          <input v-model="newTopic.name" type="text" class="form-control form-control-lg" placeholder="e.g Introduction" :class="{ 'is-invalid': $v.newTopic.name.$error, 'is-valid': !$v.newTopic.name.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.newTopic.name.required">Topic/Module name is required.</span>
          </div>
        </div>
        <button type="submit" class="btn btn-secondary mb-2" :disabled="isDisabled">{{ myButton }}</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  props: {
    courseUUID: String,
    modalID: String,
  },

  data() {
    return {
      newTopic: { name: "" },
      myButton: "Submit",
      isDisabled: false,
    };
  },

  methods: {
    topicCreate() {
      this.isDisabled = true;
      this.myButton = "Working... please wait.";

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isDisabled = false;
        return;
      }

      axios
        .post("/authoring/lessons/create-topic/" + this.courseUUID, this.newTopic)
        .then((res) => {
          this.isDisabled = false;
          if (res.data.status === "success") {
            this.$v.$reset();
            this.newTopic = {};
            this.myButton = "Submit";
            this.$bvModal.hide(this.modalID);
            this.$emit("topicCreated", res.data.lesson);
            this.$swal("Done!", res.data.message, "success");
          } else {
            this.$swal("Nope!", res.data.message, "error");
          }
        })
        .catch((error) => {
          // handle error
        });
    },
  },

  validations: {
    newTopic: {
      name: { required },
    },
  },
};
</script>

<style scoped>
</style>