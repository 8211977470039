<template>
  <!-- This component will delete selected topic/module - user is warned before deletion takes place -->
  <a href="#" @click="deleteLesson" class="text-danger">Delete</a>
</template>

<script>
import axios from "axios";

export default {
  props: {
    topic: {},
    index: Number,
  },
  methods: {
    deleteLesson() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `This action will delete the "${this.topic.name}" module and its lessons.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            axios.post("/authoring/topic/delete/" + this.topic.uuid).then((res) => {
              if (res.data.status == "success") {
                this.$emit("topicDeleted", this.index); //this.$store.state.topics.splice(topicIndex, 1);
                this.$swal.fire("Deleted!", res.data.message, "success");
              } else {
                this.$swal.fire("Oops!", res.data.message, "error");
              }
            });
          }
        });
    },
  },
};
</script>

<style>
</style>