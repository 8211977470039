<template>
  <!-- Edit selected topic -->
  <div>
    <a href="#" v-b-modal="modelID">Edit</a>

    <b-modal title="Edit Module" :id="modelID" no-close-on-esc no-close-on-backdrop hide-footer size="lg">
      <form @submit.prevent="handleUpdate" class="mt-4">
        <div class="form-group">
          <input type="text" class="form-control form-control-lg" v-model="topic.name" placeholder="Topic Title" :class="{ 'is-invalid': $v.topic.name.$error, 'is-valid': !$v.topic.name.$invalid }" />
          <div class="invalid-feedback">
            <span v-if="!$v.topic.name.required">Topic title is required.</span>
            <span v-if="!$v.topic.name.minLength">Topic title must be at least {{ $v.topic.name.$params.minLength.min }} letters.</span>
          </div>
        </div>

        <button type="submit" class="btn btn-secondary" :disabled="isDisabled">{{ editButton }}</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  props: {
    topic: {},
  },

  validations: {
    topic: {
      name: { required, minLength: minLength(3) },
    },
  },

  data() {
    return {
      editButton: "Update Module",
      isDisabled: false,
      modelID: "edit-topic-" + this.topic.uuid,
    };
  },

  methods: {
    handleUpdate(e) {
      this.isDisabled = true;
      this.editButton = "Updating Module... please wait.";

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isDisabled = false;
        return;
      }

      axios
        .post("/authoring/topic/edit/" + this.topic.uuid, this.topic)
        .then((res) => {
          this.isDisabled = false;
          if (res.data.status === "success") {
            this.$v.$reset();
            this.editButton = "Update Module";
            this.$bvModal.hide(modelID);
            this.$swal("Done!", res.data.message, "success");
          } else {
            this.$swal("Nope!", res.data.message, "error");
          }
        })
        .catch((error) => {
          // handle error
        });
    },
  },
};
</script>

<style scoped>
</style>